<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout v-if="dataRetrived" wrap justify-center px-2>
      <v-flex pa-4 style="min-width:50vw">
        <v-card outlined>
          <v-layout px-8 py-4 wrap justify-center>
            <v-flex xs12 sm12 md12 text-center>
              <span style="font-size:26px;font-weight:500;color:#464646">{{userData.username}}</span>
            </v-flex>
            <v-flex xs12 sm12 md12 text-left>
              <span style="font-size:18px;font-weight:400;color:#00a972">Files uploaded by user</span>
            </v-flex>
          </v-layout>
          <div v-for="(item,i) in userData.documents" :key="i">
            <v-layout px-4 py-1 wrap align-center>
              <v-flex xs12 sm12 md12 text-left>
                <a :href="baseURL+'/u/'+item.filename" target="_blank" download>
                  <span style="font-size:16px;font-weight:500;color:black">{{item.orginalname}}</span>
                </a>
                <span style="font-size:14px;font-weight:400;">&nbsp;({{item.name}}</span>
                <span
                  style="font-size:14px;font-weight:400;"
                  v-if="item.remark!='Accreditation Doc'"
                >&nbsp;-&nbsp;{{item.remark}})</span>
                <span style="font-size:14px;font-weight:400;" v-else>)</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </div>
          <v-layout px-8 py-4 wrap justify-center>
            <v-flex xs12 sm12 md12 text-left>
              <span style="font-size:18px;font-weight:400;color:#00a972">Files uploaded by Admin</span>
            </v-flex>
          </v-layout>
          <div v-for="(item,i) in userData.kycdocuments" :key="i">
            <v-layout px-4 wrap align-center>
              <v-flex xs12 sm12 md12 text-left>
                <a :href="baseURL+'/u/'+item.filename" target="_blank" download>
                  <span style="font-size:16px;font-weight:500;color:black">{{item.orginalname}}</span>
                </a>
                <span style="font-size:14px;font-weight:400;">&nbsp;({{item.name}}</span>
                <span
                  style="font-size:14px;font-weight:400;"
                  v-if="item.remark!='Accreditation Doc'"
                >&nbsp;-&nbsp;{{item.remark}})</span>
                <span style="font-size:14px;font-weight:400;" v-else>)</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </div>

          <v-layout v-if="!enableUpload" px-4 wrap justify-center>
            <v-flex pt-4 xs12 sm6 md6 text-center>
              <v-btn
                width="220"
                dark
                small
                color="#00a972"
                class="text-capitalize"
                @click="enableUpload=true"
              >Upload new Document</v-btn>
            </v-flex>
          </v-layout>
          <v-layout py-4>
            <v-flex></v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="enableUpload" wrap justify-center px-2>
      <v-flex pa-4 style="min-width:50vw">
        <v-card outlined>
          <v-layout px-8 py-4 wrap justify-center>
            <v-flex xs12 sm12 md12 text-center>
              <v-text-field label="Type" placeholder="eg: Pan Card" color="#00a972" v-model="type"></v-text-field>
              <v-text-field label="remark" placeholder="eg: front" color="#00a972" v-model="remark"></v-text-field>
              <v-file-input label="Select File" color="#00a972" v-model="file"></v-file-input>
            </v-flex>
          </v-layout>

          <v-layout px-4 wrap justify-center>
            <v-flex pt-4 xs12 sm6 md6 text-center>
              <v-btn
                width="220"
                dark
                small
                color="red"
                class="text-capitalize"
                @click="enableUpload=false"
              >Cancel</v-btn>
            </v-flex>
            <v-flex pt-4 xs12 sm6 md6 text-center>
              <v-btn
                width="220"
                dark
                small
                color="#00a972"
                class="text-capitalize"
                @click="uploadDoc"
              >Upload</v-btn>
            </v-flex>
          </v-layout>
          <v-layout py-4>
            <v-flex></v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      dataRetrived: false,
      userData: {},
      enableUpload: false,
      type: null,
      remark: null,
      file: null
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/info",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          id: this.id
        }
      })
        .then(response => {
          this.appLoading = false;
          this.userData = response.data.data;
          this.dataRetrived = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    uploadDoc() {
      if (!this.file || !this.type) {
        this.msg = "Please Provide Type and File";
        this.showSnackBar = true;
      } else {
        this.appLoading = true;
        let formData = new FormData();
        //   formData.append("name", this.file.name);
        formData.append("name", this.type);
        formData.append("type", this.type);
        formData.append("remark", this.remark);
        formData.append("doc", this.file);
        formData.append("userid", this.id);
        axios({
          method: "POST",
          url: "/user/kycdocument",
          headers: {
            "Content-Type": "multipart/form-data",
            "x-auth-token": localStorage.getItem("token")
          },
          data: formData
        })
          .then(response => {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.type = null;
            this.remark = null;
            this.file = null;
            this.enableUpload = false;
            this.getData();
          })
          .catch(err => {
            this.appLoading = false;
            this.msg = err.response.data.msg;
            this.msg = "Server Unreachable";
            this.showSnackBar = true;
          });
      }
    }
  }
};
</script>